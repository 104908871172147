// 话题管理相关接口
import request from '@/request/request'

// 获取话题分类
export const GetTopicCategory = (params) => request.post(`/adminv2/getTopicCategory`, params);

// 添加话题分类
export const AddTopicCategory = (params) => request.post(`/adminv2/addTopicCategory`, params);

// 更新话题分类状态
export const UpdateTopicCategoryStatus = (params) => request.post(`/adminv2/updateTopicCategoryStatus`, params);

// 获取话题分类详情
export const GetTopicCategoryDetail = (params) => request.post(`/adminv2/getTopicCategoryDetail`, params);

// 获取话题列表
export const GetTopicList = (params) => request.post(`/adminv2/getTopicList`, params);

// 更新话题状态
export const UpdateTopicStatus = (params) => request.post(`/adminv2/updateTopicStatus`, params);
