<template>
    <div class="padding24 color_white" id="third_Party_order">
        <a-spin :spinning="spinning" size="large" :tip="tip">
            <div class="isDisplay" v-show="$route.meta.isShow">
                <div class="top">
                    <a-form layout="inline">
                        <a-form-item>
                            <a-button icon="plus" class="margin_right20" type="primary" @click="addClick">添加</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <!-- 表格 -->
                <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.topicNo"  :loading="tableLoading">
                    <span slot="status" slot-scope="status, record">
                        <span>{{record.status == 1 ? '上架' : '未上架'}}</span>
                    </span>
                    <span slot="operation" slot-scope="operation, record">
                        <template v-if="isShowAddOrEdit">
                            <a id="copy_memberNo" v-if="record.status == 0" type="primary" class="margin_right10" @click="releaseClick(record)">上架</a>
                            <a-popconfirm v-if="record.status == 1"  title="确认要下架此话题吗？" ok-text="确定" cancel-text="取消" @confirm="disableClick(record)">
                                <a id="copy_memberNo" type="primary" class="margin_right10">下架</a>
                            </a-popconfirm>
                        </template>
                        <a id="copy_memberNo" v-if="isShowStatus" type="primary" class="margin_right10" @click="editClick(record)">编辑</a>
                    </span>
                </a-table>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
                <!-- 返回顶部 -->
                <a-back-top :target="targetFn" :visibilityHeight="100"/>
            </div>
        </a-spin>
        <router-view />
    </div>
</template>

<script>
import moment from "moment";
import {GetTopicCategory, UpdateTopicCategoryStatus} from "@/request/api/circleManage";
import MyPagination from "@/components/pagination/MyPagination";
import {message,popconfirm} from "ant-design-vue";
import {codeFn} from "@/utils/tools";

export default {
    components: {MyPagination},
    created() {
        this.getInitFn();
        if (codeFn("/adminv2/addTopicCategory")) this.isShowAddOrEdit = true;
        if (codeFn("/adminv2/updateTopicCategoryStatus")) this.isShowStatus = true;
    },
    data() {
        return {
            isShowAddOrEdit:false,
            isShowStatus:false,

            tip: "",
            tableLoading: true,
            spinning: false, // 全局loading
            pageNo: 1,
            pageSize: 20,
            count: 0,

            columns: [
                {
                    title: "话题名称",
                    dataIndex: "name",
                    key: "name",
                },
                {
                    title: "绑定课程",
                    dataIndex: "courseName",
                    key: "courseName",
                },
                {
                    title: "添加时间",
                    dataIndex: "showTime",
                    key: "showTime",
                },
                {
                    title: "发布状态",
                    dataIndex: "status",
                    key: "status",
                    scopedSlots: {customRender: "status"},
                },
                {
                    title: "操作",
                    fixed: "right",
                    width: 120,
                    key: "operation",
                    dataIndex: "operation",
                    scopedSlots: {customRender: "operation"},
                },
            ],
            tableData: [],
        };
    },

    watch: {
        $route(to) {
            if (to.path === "/circleManage/talkList") {
                this.getInitFn();
            }
        },
    },

    methods: {
        moment,

        // 点击添加按钮
        addClick() {
            location.href = "/circleManage/talkList/addTalk/0"
        },

        // 点击编辑按钮
        editClick(record) {
            location.href = `/circleManage/talkList/addTalk/${record.topicNo}`
        },

        // 点击上架按钮
        releaseClick(record) {
            UpdateTopicCategoryStatus({
                topicNo:record.topicNo,
                status:1
            }).then(({ code, data }) => {
                if(code == 200){
                    message.success('已上架')
                    this.getInitFn()
                }else{
                    message.success(data.msg)
                }
            });
        },

        // 点击下架按钮
        disableClick(record) {
            UpdateTopicCategoryStatus({
                topicNo:record.topicNo,
                status:0
            }).then(({ code, data }) => {
                if(code == 200){
                    message.success('已下架')
                    this.getInitFn()
                }else{
                    message.success(data.msg)
                }
            });
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getInitFn();
        },

        // 原属数据获取
        getInitFn() {
            GetTopicCategory({
                pageSize:this.pageSize,
                pageNo:this.pageNo
            }).then(({ code, data }) => {
                this.tableLoading = false
                this.tableData = data.list;
                this.count = data.count
            });
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#third_Party_order");
        },
    },
};
</script>

<style lang="less" scoped>
#third_Party_order {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.top {
    .top_line {
        margin-bottom: 20px;
        display: flex;

        span {
            margin-right: 20px;

            /deep/ .ant-input {
                width: 220px;
            }

            /deep/ .ant-select-selection {
                width: 110px;
                margin-left: 10px;
            }

            /deep/ .ant-calendar-picker-input {
                margin-left: 10px;
                width: auto;
            }

            /deep/ .ant-calendar-picker {
                width: 400px !important;
            }
        }

        .ant-btn {
            margin-right: 20px;
        }
    }
}
</style>
